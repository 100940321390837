import Layout from "../../components/layout"
import React from "react"
// import potwierdzenie from "../../files/potwierdzenie.pdf"
// import harmonogram from "@static/documents/rekrutacja_PP/Harmonogram.odt"
// import oswiadczenie2 from "@static/documents/rekrutacja_PP/oświadczenie 2 do wniosku PP.docx"
// import oswiadczenie1 from "@static/documents/rekrutacja_PP/oświadczenie do wniosku 1 PP.docx"
// import potwierdzenie from "@static/documents/rekrutacja_PP/Potwierdzenie woli_Przedszkole.doc"
// import wniosek from "@static/documents/rekrutacja_PP/wniosek o przyjęcie do przedszkola.docx"
import SEO from "../../components/seo"
import * as css from "../../components/content.module.css"

function Kindergarden() {
  return (
    <Layout menuType="kindergarden">
      <SEO title="Rekrutacja do przedszkola - ZSP Nidek" />
      <h1>Rekrutacja do przedszkola</h1>
      <p className={css.text}></p>
      <div className={css.links}>
        <p>
          {/* <a
            href="../../documents/rekrutacja_PP/Harmonogram.odt"
            download="harmonogram.odt"
          >
            Harmonogram (16KB | odt)
          </a> */}
          <a
            href="../../documents/rekrutacja_PP/zarządzenie PP.pdf"
            download="zarządzenie PP.pdf"
          >
            Zarządzenie (817KB | pdf)
          </a>
        </p>
        <p>
          <a
            href="../../documents/rekrutacja_PP/oświadczenie 2 do wniosku PP.docx"
            download="oswiadczenie2.docx"
          >
            Oświadczenie 2 do wniosku (18KB | docx)
          </a>
        </p>
        <p>
          <a
            href="../../documents/rekrutacja_PP/oświadczenie do wniosku 1 PP.docx"
            download="oswiadczenie1.docx"
          >
            Oświadczenie do wniosku (20KB | docx)
          </a>
        </p>
        <p>
          <a
            href="../../documents/rekrutacja_PP/Potwierdzenie woli_Przedszkole.doc"
            download="potwierdzenie_woli.doc"
          >
            Potwierdzenie woli przyjęcia do przedszkola (28KB | doc)
          </a>
        </p>
        <p>
          <a
            href="../../documents/rekrutacja_PP/wniosek o przyjęcie do przedszkola.docx"
            download="wniosek.docx"
          >
            Wniosek o przejęcie do przedszkola (29KB | docx)
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default Kindergarden
